<template>
  <h1>User is authenticated</h1>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("checkIsAutheticated");
  },
};
</script>